import React from 'react'
import { Link, graphql } from 'gatsby'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faCalendarAlt,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";

import Layout from '../components/layout'
import SEO from '../components/seo'
import articleStyles from './article.module.scss'

export const query = graphql`
    query ($slug: String!){
        markdownRemark(fields: {slug: {eq: $slug}}){
            frontmatter{
                title
                fdate: date(formatString: "MMM DD, YYYY")
                description
            }
            html
        }
    }
`

const News = (props) => {
    return (
        <Layout>
            <SEO
                pagetitle={props.data.markdownRemark.frontmatter.title}
                pageDesc={props.data.markdownRemark.frontmatter.description}
            />
            <div className={articleStyles.goBack}>
                <Link to="/news">
                    <h2>
                        <FontAwesomeIcon
                            icon={faArrowCircleLeft}
                            style={{ color: "#333" }}
                        />
                        &nbsp;
                        Check other news
                    </h2>
                </Link>
            </div>
            <h1>{props.data.markdownRemark.frontmatter.title}</h1>
            <p>
            <FontAwesomeIcon
                icon={faCalendarAlt}
                style={{ color: "#333" }}
            />
            &nbsp;
            {props.data.markdownRemark.frontmatter.fdate}
            </p>
            <div dangerouslySetInnerHTML={{__html: props.data.markdownRemark.html}}></div>
            <div className={articleStyles.toContact}>
                <h1>Interested in Aiki Ishin-ryu?</h1>
                <Link to="/contact">
                    <p>
                        <FontAwesomeIcon
                        icon={faPaperPlane}
                        style={{ color: "#333" }}
                        />
                        &nbsp;
                        Contact us!
                    </p>
                </Link>
            </div>
        </Layout>
    )
}

export default News